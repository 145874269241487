* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

body {
  font-weight: 200;
  font-size: 15px;
  max-width: 1600px;
  margin: 0 auto;
}
