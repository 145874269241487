.address-section {
  padding: 3rem 0;
  text-align: center;
  background-color: #0c0019;
}

.address-section h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  /* color: #fff; */
}

.address-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.address-item {
  display: flex;
  align-items: center;
  /* background-color: #0c0127; */
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(47, 9, 169, 0.1);
  max-width: 300px;
  text-align: left;
  height: 150px;
}

.icon {
  font-size: 1rem;
  color: #fff;
  margin-right: 1rem;
}

.address-details h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.address-details p {
  margin: 0;
  color: #fff;
}
