header {
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
}

.pc__nav,
.mobile__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 9000;
  background-color: #0c0019;
  padding: 20px 8rem;
  top: 0;
  left: 0;
}

.mobile__nav {
  display: none;
}

.mobile-auth-buttons {
  display: none;
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.sign-up a {
  color: #3e7ca8;
  text-decoration: none;
}

.get-started a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 900px) {
  .pc__nav {
    display: none;
  }

  .sign-up a {
    color: #fff;
    text-decoration: none;
  }

  .mobile {
    display: block;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .hamburger-menu {
    cursor: pointer;
  }

  .mobile-menu {
    display: block;
    background-color: #ffffff;
    height: 100vh;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    position: fixed;
    top: 2rem;
    z-index: 1000;
    width: 50%;
    z-index: 99999;
  }

  .mobile-menu.active {
    display: block;
  }

  .mobile-links {
    list-style: none;
    padding: 0;
    /* text-align: center; */
  }

  .mobile-links li {
    margin-bottom: 30px;
  }

  .mobile-links li a {
    text-decoration: none;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
  }

  .logo {
    width: 30px;
    height: 30px;
  }
}
