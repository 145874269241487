.hero {
  padding: 0 8rem;
  background-color: #0c0019;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.hero__img {
  object-fit: cover;
}

.hero__img img {
  width: 100%;
}

.hero__text {
  color: #fff;
}

.hero__text p {
  font-weight: 600;
}

.hero__text h3 {
  font-size: 2.5rem;
  text-transform: uppercase;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .hero {
    padding: 0 6rem;
    /* height: 30vh; */
  }

  .hero__text {
    margin: 3rem;
  }

  .hero__text h3 {
    font-size: 2.5rem;
  }
}

@media (max-width: 992px) {
  .hero {
    padding: 0 4rem;
    flex-direction: column;
    text-align: center; /* Center text on smaller screens */
  }

  .hero__text h3 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 0 2rem;
  }

  .hero__text h3 {
    font-size: 125rem;
  }
}

@media (max-width: 576px) {
  .hero {
    padding: 0 1rem;
  }

  .hero__text h3 {
    font-size: 1.2rem;
  }
}
