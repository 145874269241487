.features {
  padding: 1rem 8rem;
}

.feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature--reverse {
  flex-direction: row-reverse;
}

.feature__image,
.feature__text {
  width: 40%;
  margin: 1rem;
}

.feature__image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  /* border-radius: 50%; */
}

.feature__text h3 {
  margin-top: 10px;
  font-size: 2rem;
}

.feature__text p {
  margin: 10px 0;
  font-size: 1rem;
}

.feature__text button {
  padding: 10px 20px;
  background-color: #e91e63;
  color: white;
  border: none;
  cursor: pointer;
}

.feature__text button:hover {
  background-color: #e91e62cc;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .features {
    padding: 1rem 4rem; /* Adjust padding for medium screens */
  }

  .feature__image,
  .feature__text {
    width: 45%; /* Slightly adjust width for medium screens */
  }

  .feature__image img {
    height: 300px; /* Adjust image height for medium screens */
  }
}

@media (max-width: 992px) {
  .features {
    padding: 1rem 2rem; /* Further adjust padding for tablets */
  }

  .feature__image,
  .feature__text {
    width: 100%; /* Full width for both elements on tablets */
  }

  .feature {
    flex-direction: column; /* Stack elements vertically on tablets */
    align-items: center; /* Center align items */
  }

  .feature__image img {
    height: 250px; /* Adjust image height for tablets */
  }
}

@media (max-width: 768px) {
  .features {
    padding: 1rem 1rem; /* Adjust padding for mobile screens */
  }

  .feature__image img {
    height: 200px; /* Further adjust image height for mobile screens */
  }

  .feature__text h3 {
    font-size: 1.5rem; /* Adjust font size for mobile screens */
  }

  .feature__text p {
    font-size: 0.875rem; /* Adjust font size for mobile screens */
  }

  .feature__text button {
    padding: 8px 16px; /* Adjust button padding for mobile screens */
  }
}

@media (max-width: 480px) {
  .feature__text h3 {
    font-size: 1.25rem; /* Smaller font size for very small screens */
  }

  .feature__text p {
    font-size: 0.75rem; /* Smaller font size for very small screens */
  }

  .feature__text button {
    padding: 6px 12px; /* Smaller button padding for very small screens */
  }
}
